import * as React from "react"
import Seo from "../components/seo/seo"
import BigBanner from "../shared/big-banner/BigBanner"
import ProjectsList from "../shared/projects-list/ProjectsList"
import AboutSection from "../shared/about-section/AboutSection"
import BannersSlider from "../shared/banners-slider/BannersSlider"
import Partners from "../shared/partners/Partners"
import Header from "../components/header/header"
import { Link } from "gatsby"
import Footer from "../components/footer/footer"

const IndexPage = props => {
  const staticContent = props.pageContext.staticContent
  const portfolio = props.pageContext.resultPortfolio
  const gallery = props.pageContext.resultGallery
  const partners = props.pageContext.partners
  const projects = props.pageContext.resultProjects
  const menu = props.pageContext.menu
  const staticHeaderPage = props.pageContext.staticHeaderPage
  const staticGallery = props.pageContext.staticGallery
  gallery.sort(function (a, b) {
    return a?.position - b?.position
  })

  return (
    <div className="index-wrapper">
      <Header
        menu={menu}
        siteTitle={staticContent["<title>"]}
        contents={staticHeaderPage}
        displayLogoLinks={true}
      />
      <Seo
        keywordsContent={staticContent["<meta> keywords"]}
        descriptionContent={staticContent["<meta> description"]}
        title={staticContent["<title>"]}
      />
      <div className="container">
        <BigBanner
          title={staticContent["Big Banner Title"]}
          text={staticContent["Big Banner Text"]}
          buttonText={staticContent["Big Banner Button Text"]}
          buttonLink={staticContent["Big Banner Button Link"]}
          textColourScheme="dark"
          pageName="main"
        />
      </div>
      <ProjectsList
        projects={projects}
        itemsInRow={4}
        learnMoreBtnText={projects?.detailsTitle}
        video={false}
      />

      <AboutSection
        boards={staticContent["Highlight 1 Text"]}
        boardsNumber={staticContent["Highlight 1"]}
        settlements={staticContent["Highlight 2 Text"]}
        settlementsNumber={staticContent["Highlight 2"]}
        clients={staticContent["Highlight 3 Text"]}
        clientsNumber={staticContent["Highlight 3"]}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-between align-items-center mb-5 flex-wrap">
            <h1 className="">{staticContent["Portfolio Title"]}</h1>
            <Link to="/portfolio">
              <button type="button" className="btn btn-primary btn-lg">
                {staticContent["Portfolio Button Text"]}
              </button>
            </Link>
          </div>
        </div>
      </div>

      <BannersSlider
        buttonNeeded={false}
        uniqueId={"portfolio-main-page-carousel"}
        items={portfolio}
        staticPortfolioProPage={staticContent}
      />
      <Partners
        items={partners}
        title={staticContent["Partners Title"]}
      />
      <div className="container">
        <div className="row">
          <h1 className="mb-5 col-12">{staticContent["Gallery Title"]}</h1>
        </div>
      </div>
      <BannersSlider
        buttonNeeded={false}
        uniqueId={"gallery-main-page-carousel"}
        staticGallery={staticGallery}
        title={staticContent["Gallery Title"]}
        items={gallery}
        isGallery={true}
        buttonText={staticGallery["Details link title"]}
        sitesText={staticContent["Number of sites text"]}
      />
      <Footer
        recaptchaSettings={props.pageContext.recaptchaSettings}
        staticContactsForm={props.pageContext.staticContactsForm}
        staticContactsPage={props.pageContext.staticContactsPage}
      />
    </div>
  )
}

export default IndexPage
