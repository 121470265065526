import React, { useEffect } from "react"
import "./OneSlideViewSlider.scss"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper"
import "swiper/css"
import { getImageUrl } from "../../services/Utils"

SwiperCore.use([Navigation])

const OneSlideViewSlider = ({
  title,
  items,
  descNeeded,
  type,
  itemKey,
  bottomSizes,
  getSizes,
}) => {
  useEffect(() => {
    if (bottomSizes) {
      getSizes(items[0])
    }
  }, [])
  return (
    <div className={`one-slide-view-slider`}>
      <Swiper
        id={`id_${itemKey}`}
        key={itemKey}
        className={`row sw_${itemKey}`}
        slidesPerView={1}
        spaceBetween={200}
        navigation={{
          nextEl: `.custom-next-${itemKey}`,
          prevEl: `.custom-prev-${itemKey}`,
        }}
      >
        {items && items.length
          ? items.map((item, index) => {
            return (
              <SwiperSlide key={item.id} className="">
                <div className="projects-list__item col-lg-12 position-relative justify-content-center">
                  {type === "video" ? (
                    <iframe
                      type="text/html"
                      key={1}
                      title="YouTube video player"
                      frameBorder="0"
                      allowFullScreen
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      src={item.videoLink}
                    />
                  ) : (
                    <div
                      key={index}
                      className="gallery-image-wrapper d-flex justify-content-center"
                    >
                      <img
                        className="slider-image"
                        src={getImageUrl(item.imageUrl) || item}
                      />
                    </div>
                  )}
                  {items.length > 1 ? (
                    <div className="btn-wrapper d-flex justify-content-between align-items-center">
                      <div
                        className={`prev-arrow ps-6 custom-prev-${itemKey}`}
                        onClick={() => {
                          if (bottomSizes && items.indexOf(item) - 1 >= 0) {
                            getSizes(items[items.indexOf(item) - 1])
                          }
                        }}
                      />
                      <div
                        className={`next-arrow pe-8 custom-next-${itemKey}`}
                        onClick={() => {
                          if (bottomSizes && items.indexOf(item) + 1 <= items.length) {
                            getSizes(items[items.indexOf(item) + 1])
                          }
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                {descNeeded && (
                  <div className="slide-description mt-3">
                    {item.description}
                  </div>
                )}
              </SwiperSlide>
            )
          })
          : null}
      </Swiper>
    </div>
  )
}

export default OneSlideViewSlider
