import * as React from "react"
import "./AboutSection.scss"

const AboutSection = props => {
  return (
    <div className="container">
      <div className="about-section row py-7">
        <div className="about-section__information-item col-lg-4 mb-3">
          <h2 className="great-numbers">{props.boardsNumber}</h2>
          <div className="description">{props.boards}</div>
        </div>
        <div className="about-section__information-item col-lg-4">
          <h2 className="great-numbers">{props.settlementsNumber}</h2>
          <div className="description">
            {props.settlements}
          </div>
        </div>
        <div className="about-section__information-item col-lg-4">
          <h2 className="great-numbers">{props.clientsNumber}</h2>
          <div className="description">{props.clients}</div>
        </div>
      </div>
    </div>
  )
}

export default AboutSection
