import React, { useState } from "react"
import "./BannersSlider.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import LearnMoreBtn from "../learn-more-btn/LearnMoreBtn"
import OneSlideViewSlider from "../one-slide-view-slider/OneSlideViewSlider"
import { Modal } from "react-bootstrap"

import { Link } from "gatsby"

import miniIcon from "../../images/board-type.png"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, A11y } from "swiper"
import "swiper/css"
import { getImageUrl } from "../../services/Utils"

SwiperCore.use([Navigation, A11y])

const BannersSlider = props => {
  const items = props.items

  const buttonNeeded = props.buttonNeeded
  const title = props.title
  const staticPortfolioProPage = props?.staticPortfolioProPage
  const staticGallery = props?.staticGallery

  const [videoLink, setVideoLink] = useState()
  const [itemImageModal, setItemImageModal] = useState({})

  const isGalleryItems = props.isGallery


  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = link => {
    setVideoLink(link)
    setShow(true)
  }

  const [showGallery, setShowGallery] = useState(false)
  const handleCloseGallery = () => setShowGallery(false)

  const handleShowGallery = (picture, item) => {
    setItemImageModal(item)
    handleCloseGalleryTemplate()
    setShowGallery(true)
  }

  const [showGalleryTemplate, setShowGalleryTemplate] = useState(false)
  const handleCloseGalleryTemplate = () => setShowGalleryTemplate(false)

  const handleShowGalleryTemplate = picture => {
    handleCloseGallery()
    setShowGalleryTemplate(true)
  }

  const resolveBoardType = iconFile => {
    const image = getImage(iconFile)
    if (!image) {
      return <></>
    }
    return (
      <GatsbyImage
        image={image}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Project picture"
        className="board-type"
      />
    )
  }

  const picturesArray = items.map(board => {
    return board.realPicture
  })

  const [boardSizes, setBoardSizes] = useState()

  const getSizes = data => {
    if (data && data.size) {
      setBoardSizes({
        size: data.size,
        amount: data.amount,
        previewIcon: data.previewIcon,
      })
    }
  }

  return (
    <div className="">
      <div className="banners-slider container">
        <div className="row justify-content-center">
          <div className="">
            <Swiper
              id={props.uniqueId}
              key={props.uniqueId}
              className={`row ${props.uniqueId}`}
              navigation={{
                nextEl: `.next-arrow.${props.uniqueId}`,
                prevEl: `.prev-arrow.${props.uniqueId}`,
              }}
              breakpoints={{
                375: {
                  slidesPerView: 1,
                },
                986: {
                  slidesPerView: 2,
                },
              }}
            >
              {items && items.length
                ? items.map(item => {
                  return (
                    <SwiperSlide key={item.id} className="col-lg-6">
                      <div
                        className="projects-list__item d-flex flex-column justify-content-between h-100"
                      >
                        <div className="">
                          <div className="image-board-type-wrapper position-relative curs-P">
                            {isGalleryItems ? (
                              <div className="banners-slider-image content">
                                <div
                                  className="content-overlay"
                                  onClick={() =>
                                    isGalleryItems &&
                                    handleShowGallery(item?.realPicture, item)
                                  }
                                ></div>
                                <div
                                  className={`col-1 ps-5 prev-arrow ${props.uniqueId}`}
                                />
                                <div
                                  className={`col-1 pe-5 next-arrow ${props.uniqueId}`}
                                />
                                <img
                                  className="content-image"
                                  src={getImageUrl(item?.images[0]?.imageUrl)}
                                  alt="No image"
                                />
                              </div>
                            ) : (
                              <div className="content">
                                <div
                                  className="content-overlay"
                                  onClick={() =>
                                    !isGalleryItems &&
                                    handleShow(item?.videoLink)
                                  }
                                ></div>

                                <div
                                  className={`ps-5 col-1 white-arrow prev-arrow ${props.uniqueId}`}
                                />
                                <div
                                  className={`pe-5 col-1  white-arrow next-arrow ${props.uniqueId}`}
                                />
                                {item.icon &&
                                  !isGalleryItems &&
                                  resolveBoardType(item?.localImage)}

                                <img
                                  className="content-image"
                                  src={getImageUrl(item?.imageUrl)}
                                  alt="No image"
                                />
                              </div>
                            )}
                          </div>
                          {isGalleryItems && (
                            <div className="sizes mb-3 mt-3">
                              {item.images[0]?.amount} {props.sitesText} —{" "}
                              {item.images[0]?.size}
                            </div>
                          )}
                          <div className="title-description-wrapper mt-3">
                            <div className="title mb-3">{item?.title}</div>
                            <div
                              className="description mb-5"
                              dangerouslySetInnerHTML={{
                                __html: item?.description,
                              }}
                            />
                          </div>
                        </div>
                        {isGalleryItems ? (
                          <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <LearnMoreBtn
                                buttonId="details"
                                urlName={item.urlName}
                                buttonText={props?.buttonText}
                              />
                            </div>
                            <div className="col-lg-6">
                              <LearnMoreBtn props={item} />
                            </div>
                            {/* </div> */}
                          </div>
                        ) : (
                          <LearnMoreBtn props={item} />
                        )}
                      </div>
                    </SwiperSlide>
                  )
                })
                : null}

              <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                contentClassName="video-modal"
              >
                <Modal.Body>
                  <iframe
                    type="text/html"
                    key={1}
                    title="YouTube video player"
                    frameBorder="0"
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    src={videoLink}
                  />
                </Modal.Body>
              </Modal>
              <Modal
                {...props}
                show={showGallery}
                onHide={handleCloseGallery}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="gallery-modal"
              >
                <Modal.Body>
                  <div className="position-relative">
                    <OneSlideViewSlider
                      items={itemImageModal?.images}
                      bottomSizes={true}
                      getSizes={getSizes}
                    />
                    <div
                      className="mini-icon-item d-flex align-items-center justify-content-center pt-3 curs-P position-absolute"
                      onClick={() => {
                        handleShowGalleryTemplate()
                      }}
                    >
                      <img src={getImageUrl(boardSizes?.previewIcon)}></img>
                    </div>
                    <div className="board-description-text w-100">
                      {staticGallery && (
                        <div className="text-center">
                          {staticGallery["Format"]}: {boardSizes?.size}
                          &nbsp;&nbsp;&nbsp; {staticGallery["Planes"]}:&nbsp;
                          {boardSizes?.amount}
                        </div>
                      )}
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                {...props}
                show={showGalleryTemplate}
                onHide={handleCloseGalleryTemplate}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="gallery-modal real-pictures"
              >
                <Modal.Body>
                  <div className="position-relative">
                    <OneSlideViewSlider items={picturesArray} />
                    <div
                      className="mini-icon-item d-flex align-items-center justify-content-center pt-3 curs-P position-absolute"
                      onClick={handleShowGallery}
                    >
                      <img alt="title" src={miniIcon}></img>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </Swiper>
          </div>
        </div>
        <div className="btn-wrapper d-flex justify-content-center align-items-center mt-3"></div>
        {isGalleryItems && <div className="empty-divider"></div>}
      </div>
    </div>
  )
}

export default BannersSlider
